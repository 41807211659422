<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getSmplsNow()">
          <el-row>
            <el-col :md="6">
              <el-form-item label="单据编号：">
                <el-input size="small" v-model.trim="searchForm.smpl_no" clearable placeholder="请填写单据编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <el-form-item label="产品货号：">
                <el-input size="small" v-model.trim="searchForm.prod_no" clearable placeholder="请填写产品货号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <el-form-item label="产品名称：">
                <el-input size="small" v-model.trim="searchForm.prod_name" clearable placeholder="请填写产品名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <el-form-item label="产品状态：">
                <el-select size="small" v-model.trim="searchForm.status" placeholder="请选择产品状态" clearable @visible-change="getSmplType($event)">
                  <el-option
                      v-for="item in smplTypeGroupOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="6">
              <el-form-item label="委托单号：">
                <el-input size="small" v-model.trim="searchForm.smpl_ask_no" clearable placeholder="请填写委托单号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="14">
              <el-form-item label="交样时间：">
                <div class="block">
                  <el-date-picker
                      size="small"
                      v-model="searchForm.timeValue"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getSmplsNow()" class="vg_ml_16">查询</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table class="vg_pointer" ref="multiTable" :data="tableData" @selection-change="handleSelectionChange" @select="selectRows" @select-all="selectRows" @row-dblclick="dbClickJp" border>
            <el-table-column type="selection" width="48"  align="center"/>
            <el-table-column label="单据编号" prop="smpl_no"/>
            <el-table-column label="产品货号" prop="prod_no"/>
            <el-table-column label="委托单号" prop="smpl_ask_no"/>
            <el-table-column label="产品名称" prop="prod_name" :show-overflow-tooltip="true"/>
            <el-table-column label="打样数量" prop="smpl_num" />
            <el-table-column label="打样规格" show-overflow-tooltip prop="prod_spec" />
            <el-table-column label="交样时间" prop="smpl_deadline" :formatter="formatDate1" />
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina"/>
            <el-table-column label="录入时间" prop="create_time" :formatter="formatDate" />
            <el-table-column label="产品状态" align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{helper.getStatusName(scope.row.status).name}}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {smplAPI} from "@api/modules/smpl"
import options from "@/views/component/common/options";
import pubPagination from "@/components/common/pubPagination";
export default {
name: "smplList",
  components: {
    pubPagination
  },
  data() {
    return {
      searchForm: {
        prod_no: '',
        smpl_no: '',
        smpl_ask_no:'',
        prod_name:null,
        status:null,
        timeValue:{
          startTime:null,
          endTime:null
        }
      },
      currentPage: 1,
      totalPage:0,
      tableData: [],
      multiSelection: [],
      smplTypeGroupOpt:[],
      btn:{},
    }
  },
  created() {
    this.initData()
  },
  watch:{
    $route(to,from){
      if(from.path === '/smpl_add' || from.path === '/smpl_edit'){
        this.initData()
      }
    },
  },
  methods: {
    initData(){
      this.getSmpls();
    },
    // 获取产品信息
    getSmpls() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      get(smplAPI.getSmpls,
          {
            prod_no: this.searchForm.prod_no,
            smpl_no: this.searchForm.smpl_no,
            prod_name: this.searchForm.prod_name,
            smpl_ask_no:this.searchForm.smpl_ask_no,
            status: this.searchForm.status,
            start_time: timeNewVal.startTime,
            end_time: timeNewVal.endTime,
            page_no: this.currentPage,
          })
          .then(res => {
            if(res.data.code === 0){
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total
              this.btn = res.data.data.btn;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 刷新
    buttonRefresh(){
      this.searchForm = {
        prod_no: '',
        smpl_no: '',
        smpl_ask_no:'',
        prod_name:null,
        status:null,
        timeValue:{
          startTime:null,
          endTime:null
        }
      };
      this.currentPage = 1
      this.initData()
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time)
    },
    formatDate1(row) {
      return this.helper.toStringDate(row.smpl_deadline)
    },
    // 获取产品状态
    getSmplType(flag){
      if(flag === true && this.smplTypeGroupOpt.length === 0){
        this.smplTypeGroupOpt = options.smplStatus
      }
    },
    //产品状态
    formatSmplType(row){
      if(row.status === 0){
        return '草拟'
      } else if(row.status === 1){
        return '在批'
      } else if(row.status === 2){
        return '生效'
      }
    },
    // 查询方法
    getSmplsNow(){
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.getSmpls()
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.getSmpls()
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection){
      this.btn.delete = this.helper.isDelete(selection)
    },
    // 新增
    addRow(){
      const permId = this.$route.query.perm_id
      this.jump(`/smpl_add?perm_id=${permId}`)
    },
    //删除提示
    doDelete(){
      if(this.multiSelection.length === 0){
        this.$message({type:'warning',message:'请至少选择一条数据！'})
      } else {
        this.mBox()
      }
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delRow()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
        this.$refs.multiTable.clearSelection();
      });
    },
    // 删除
    delRow(){
      const ids = []
      this.multiSelection.map((item)=>{
        ids.push(item.smpl_id)
      })
      post(smplAPI.destroySmplByIds,{'smpl_id_list':ids})
          .then(res=>{
            if(res.data.code === 0){
              let mg = res.data.msg;
              let tp = 'success';
              this.$message({message:mg,type:tp});
              this.initData()
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
              this.initData()
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 双击row跳转到详情编辑
    dbClickJp(row){
      const permId = this.$route.query.perm_id
      this.jump('/smpl_edit',{perm_id:permId,form_id:row.smpl_id})
    }
  }
}
</script>

<style scoped lang="scss">
.vd_button_group{
  width: 100vw;
  position: relative;
}
.vd_txta {
  position: absolute;
  left: 35%;
}
</style>